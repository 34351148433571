.widget-grid {
    width: 780px;
}
.login-btn {
    display: flex;
    align-items: center;
    align-self: center;
    flex-direction: column;
}

#background-content {
    background-color: black;
}

.landing-logo {
    width: 200px;
    height: 200px;
}
.first-widget-row {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 820px) {
    .widget-grid {
        width: 700px;
    }
}

@media screen and (max-width: 760px) {
    .widget-grid {
        width: auto;
    }
}
