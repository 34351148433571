.widgets-login {
    display: flex;
    justify-content: center;
}

.widget-example {
    padding-bottom: 50px;
    padding-top: 10px;
    padding-right: 50px;
    width: 600px;
    pointer-events: none;
}

.widget-examples-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.widget-preview {
    padding-bottom: 50px;
    padding-top: 10px;
    padding-right: 50px;
    width: 600px;
}
