.form-control {
    background-color: white;
    border: none;
}

.form-control input::placeholder {
    color: red;
}

.special-label {
    display: none;
}

#permit-address {
    background-color: white;
}

.permit-form {
    text-align: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.permit-form ion-grid {
    width: 100%;
}

.address-title {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-left: 50px;
    align-items: center;
}

#address-btn {
    margin-top: 2em;
    margin-left: 24px;
    text-decoration: underline;
    color: blue;
}

#user-address-btn {
    margin-top: 1em;
    margin-left: 1em;
    text-decoration: underline;
    color: blue;
}
#user-address-btn2 {
    margin-top: 1em;
    margin-left: 2em;
    text-decoration: underline;
    color: blue;
}
#user-address-btn2:hover {
    cursor: pointer;
}

#address-btn:hover {
    cursor: pointer;
}
#user-address-btn:hover {
    cursor: pointer;
}
.address-form {
    text-align: flex-start;
}

/* .permit-form-grid {
  border: 3px solid rgb(102, 102, 102);
} */

.alert-msg {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #ec335b;
}

p {
    margin: 4px;
}

.native-input {
    border: 1px solid black;
}

ion-input {
    margin: 0;
}

.permit-form-col {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 30px;
}

.address-search {
    padding-left: 40px;
    padding-right: 30px;
}

.form-btns {
    padding-left: 40px;
}

.form-inputs {
    margin: 0;
}

.error-msg {
    padding-left: 1em;
    color: #ec335b;
    margin: 0;
}

.hide-error-msg {
    display: none;
    margin: 0;
}

.phone-input {
    margin-top: 6px;
    border: none !important ;
}

.register-admin-title {
    font-size: 18px;
    margin-left: 10px;
    margin-top: 35px;
}
