.weather-icon {
    display: flex;
    text-align: center;
    align-items: center;
    align-self: center;
    color: rgb(0, 72, 150);
    padding-bottom: 0px;
    justify-content: center;
}

#temp-number {
    font-size: 60px;
}

.weather-card {
    height: 365px;
    width: 100%;
    margin: 0;
}

#weather-current {
    margin-top: 23px;
}

#temp-degree {
    color: rgb(0, 72, 150);
    font-size: 48px;
    font-weight: 800;
    top: -10px;
    position: relative;
    left: 2px;
}
