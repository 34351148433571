.text-muted {
    color: #aeb0b4 !important;
}
.text-muted {
    font-weight: 300;
}

.nav-link:hover {
    cursor: pointer;
}

ion-card {
    height: auto;
}
