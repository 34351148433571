.full {
    width: 100vw;
    height: 100vh;
}

.auto {
    width: 80vw;
    height: 80vh;
}

@media screen and (max-width: 1600px) {
    .auto {
        width: 77vw;
    }
}

@media screen and (max-width: 1455px) {
    .auto {
        width: 74vw;
    }
}

@media screen and (max-width: 1300px) {
    .auto {
        width: 71vw;
    }
}

@media screen and (max-width: 1155px) {
    .auto {
        width: 69vw;
    }
}

@media screen and (max-width: 991px) {
    .auto {
        width: 95vw;
    }
}
