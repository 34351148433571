#login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.link-btns:hover {
    cursor: pointer;
}

.login-recap {
    display: flex;
    justify-content: center;
}
