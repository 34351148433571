.fail {
    color: green;
}

.pass {
    color: red;
}

.search-bar {
    align-items: center;
}

#check-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
}

.auto-complete-list {
    color: black;
}

#searchbar {
    background-color: rgba(9, 165, 255, 0.253);
    border-color: black;
}

.check-address {
    width: 100%;
    height: 1em;
}
