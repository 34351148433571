.email-input-div {
    display: flex;
    flex-direction: row;
}
.email-input {
    border: 1px solid grey;
    border-radius: 10px;
    margin: 0;
}
.emergency-header {
    padding-bottom: 20px;
}
.native-input.sc-ion-input-ios {
    margin-left: 10px;
    margin-top: 0;
    margin-bottom: 0;
}
.emergency-card {
    margin: 0;
    width: 100%;
    margin-bottom: 20px;
}
.hide {
    display: none;
}
.err {
    color: red;
    padding-top: 5px;
}
.success {
    color: green;
    padding-top: 5px;
}

.emergency-recap {
    display: flex;
    justify-content: center;
    padding-top: 16px;
}

.update-div {
    display: flex;
    flex-direction: row;
}

.emergency-btns:hover {
    cursor: pointer;
}
