.nopw-input {
    border: 0.5px solid rgb(181, 179, 179);
    width: 340px;
    margin-bottom: 10px;
}

.hide {
    display: none;
}
.err {
    color: red;
    padding-top: 5px;
}
