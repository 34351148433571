.left-side-input {
    text-align: left;
    margin-left: 16px;
    margin-top: 1px;
}

.form-control {
    color: #858383;
    font-size: 14px;
    width: 200px;
}

.item-native {
    padding: 0;
}

.announcement-card {
    width: 97%;
    height: auto;
    padding-left: 16px;
}

.form-toggle {
    display: flex;
    align-self: center;
    justify-content: flex-start;
    align-items: center;
    margin-left: 16px;
}

.title-input {
    pointer-events: none;
}
