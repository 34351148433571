.announ-item {
    display: flex;
    flex-direction: column;
    height: auto;
}

.announcement-date {
    font-weight: 600;
}

.announ-div {
    display: flex;
    justify-content: center;
    width: 100%;
}

.announ-card {
    width: 100%;
    margin: 0;
}

.announ-item-link {
    display: flex;
    flex-direction: column;
    height: auto;
}
.announ-item-link:hover {
    cursor: pointer;
}
