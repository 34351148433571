.form-control {
    background-color: white;
    border: none;
}

.form-control input::placeholder {
    color: red;
}

.special-label {
    display: none;
}

#permit-address {
    background-color: white;
}

.permit-form {
    text-align: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.permit-form ion-grid {
    width: 100%;
}

.address-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#address-btn {
    margin-top: 2em;
    margin-right: 1em;
    text-decoration: underline;
    color: blue;
}

#address-btn:hover {
    cursor: pointer;
}

.address-form {
    text-align: flex-start;
}

/* .permit-form-grid {
  border: 3px solid rgb(102, 102, 102);
} */

#permit-type-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
