.permit-icon {
    width: 100px;
    height: 100px;
    padding: 10px 10px;
}

.permit-icon-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.permit-card:hover {
    cursor: pointer;
}

#permit-type {
    font-weight: 600;
    font-size: 28px;
    margin-right: 1.4em;
}

.pdf-open {
    display: flex;
    flex-direction: column;
}

.toggle-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.toggle {
    display: flex;
    flex-direction: row;
}

.toggle-text {
    align-items: center;
    align-self: center;
    margin-left: 10px;
}

.permit-card:hover {
    cursor: pointer;
}
