.contact-card {
    width: 100%;
}

.contact-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contact-header {
    font-weight: 300;
    font-size: 28px;
}

.contact-header-div {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.contact-col {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 25px;
    padding: 0;
    padding-top: 5px;
    width: auto;
}
.contact-col-address {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    padding: 5px;
    padding-top: 5px;
}
.contact-icon {
    height: 30px;
    width: 30px;
}
