.no-pw-header-text {
    font-size: 28px;
    font-weight: 700;
    text-align: center;
}
.no-pw-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
