.blurb-input {
    border: 1px solid #cccccc;
    width: 93%;
    height: 300px;
    margin-left: 16px;
}
.native-textarea.sc-ion-textarea-md {
    height: 300px;
}
.form-control {
    color: #858383;
    font-size: 14px;
    width: 200px;
}

ion-input {
    margin-bottom: 10px;
    border: none;
    margin: 0;
    /* text-align: right; */
}

.item-native {
    padding: 0;
}
