.App {
    font-family: sans-serif;
    text-align: center;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.search-bar {
    display: flex;
    width: 40vw;
}

button {
    height: 3em;
    align-self: center;
}

.main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.info-window {
    color: black;
}
