.profile-card {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
@media screen and (min-width: 700px) {
    .profile-card {
        width: 70%;
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }
}
.my-profile {
    display: flex;
    justify-content: center;
}

.profile-item {
    padding: 20px;
}

#profile-name {
    color: black;
    font-weight: 500;
}

.profile-modal-nav {
    padding: 5px 5px;
    border-bottom: 1px solid rgb(184, 182, 182);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    align-self: center;
    height: 37px;
}

.close-modal-icon {
    height: 20px;
    width: 20px;
    color: #3880ff;
}

.back-btn-div {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    align-self: center;
    color: #3880ff;
}

.back-modal-icon {
    height: 15px;
    width: 15px;
}

.back-modal-text {
    margin-bottom: 2px;
}

.back-btn-div:hover {
    cursor: pointer;
}

#google-logo {
    width: 200px;
}

.expand-btn {
    width: 90%;
    color: #3880ff;
}

.edit-icon {
    height: 30px;
}
.delete-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-self: center;
    align-items: center;
}
.delete-div:hover {
    cursor: pointer;
}
.delete-btn {
    margin: 0;
    color: red;
    text-decoration: underline;
}

.delete-icon {
    color: red;
    height: 30px;
    width: 30px;
}
