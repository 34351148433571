button {
    margin: 1em 1em;
}

.info-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* #delete-modal-text {
  color: black;
}

#delete-modal-text body.dark {
  color: white;
} */

/* .delete-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
} */
