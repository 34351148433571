.login-toggle {
    display: flex;
    align-self: center;
    justify-content: space-between;
    align-items: center;
}

.risk-level-bar {
    width: 500px;
    --border-width: 0 0 0px 0;
}
