ion-content {
    --background: #f0f0f0;
}
ion-menu ion-content {
    --background: #fff !important;
}
.activity-grid ion-col {
    text-align: center;
    border-bottom: 1px solid black;
}
.subscribe-icon {
    color: red;
}

.inspect-icon {
    color: red;
}

.inspect-icon.inspected {
    color: green;
}
ion-icon:hover {
    cursor: pointer;
}

.cancel-sub-btn {
    color: red;
}

.spinner {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: space-around;
}

.in-spinner {
    display: flex;
    align-items: center;
    align-self: center;
}

.css-pdct74-MuiTablePagination-selectLabel,
.css-levciy-MuiTablePagination-displayedRows {
    margin: 0;
}
