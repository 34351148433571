.account-card {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
h2 {
    font-weight: 800 !important;
    font-size: 24px !important;
}
.burn-coord-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
    padding: 30px;
}

.modal-address-search {
    width: 80%;
    padding-left: 16px;
}

.manual-address-btn {
    padding-left: 16px;
    color: blue;
    text-decoration: underline;
}
.manual-address-btn:hover {
    cursor: pointer;
}

.manual-address {
    display: flex;
    width: 80%;
}

.manual-address-form {
    width: 100%;
    margin-left: 3em;
}

.profile-toggle {
    display: flex;
    align-self: center;
    align-items: center;
    text-align: center;
    padding-left: 24px;
}
.apply-permit-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.permit-type-item {
    width: 100%;
}

.profile-App {
    height: 400px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
    width: 77%;
}

.profile-main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    margin-top: 10px;
}

.burn-coord-text {
    margin: 0;
    margin-bottom: 5px;
}

.terms-title {
    font-size: 1.5rem;
    font-weight: 700;
}
.term-file {
    color: blue;
    font-size: 20px;
    text-align: center;
}

.term-file:hover {
    cursor: pointer;
}
.streets-blurb {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 1rem auto;
    font-size: 1rem;
    font-weight: 700;
}
.modal-btn {
    width: 90%;
}

.modal-title {
    align-self: flex-start;
    margin-left: 30px;
    font-weight: 750;
}

.modal-title-mid {
    font-weight: 750;
}

.sub-item {
    align-self: flex-start;
    margin-left: 30px;
    width: 90%;
}

.confirm-title {
    font-weight: 750;
}

.permit-modal-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.back {
    text-align: center;
    margin-right: 40px;
    font-size: 20px;
    font-weight: 700;
}

.no-back {
    text-align: center;
    margin-right: -55px;
    font-size: 20px;
    font-weight: 700;
}

@media screen and (max-height: 800px) {
    ion-modal {
        --height: 95vh;
    }
}
